import React from 'react';
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import ImageWrapper from 'components/helpers/ImageWrapper/ImageWrapper';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Heading from 'components/helpers/Heading/Heading';
import { DevLog } from 'lib/utils/logging-utils';
import { PriorAuthInputs } from './PriorAuthLookup';
import { DateTime } from 'luxon';
import useLocalTimeZone from 'lib/hooks/useLocalTimeZone';

export type PriorAuthLookupAuthFalseResultsProps = Sitecore.Override.ComponentBase &
  Feature.Components.PriorAuthLookup.Fields.PriorAuthLookup &
  PriorAuthInputs &
  any;

export type ResultMessages = Feature.Data.Priorauth.ResultMessages.Fields.ResultMessages;

const PriorAuthLookupAuthFalseResults = ({
  fields,
  resultMessage,
  responseData,
  priorAuthInputs,
}: PriorAuthLookupAuthFalseResultsProps) => {
  DevLog('PriorAuthLookupAuthFalseResults fields = ', fields);
  DevLog('PriorAuthLookupAuthFalseResults resultMessage = ', resultMessage);
  DevLog('PriorAuthLookupAuthFalseResults responseData = ', responseData);

  DevLog('PriorAuthLookupAuthFalseResults priorAuthInputs = ', priorAuthInputs);

  const timeZoneName = useLocalTimeZone();

  return (
    <div className={classNames('text-left', 'pb-12', 'pt-2', 'px-2', 'md:px-12')}>
      {/* Heading Section */}
      <div className={classNames('pb-10', 'flex', 'items-center', 'space-x-4')}>
        <ImageWrapper
          field={{
            value: {
              src: resultMessage?.fields?.iconImage?.value?.src,
              width: '25px',
              height: '25px',
            },
          }}
          imageClass="w-[25px] h-[25px] flex-shrink-0 object-contain"
        />
        <Heading
          headingText={{ value: resultMessage?.fields?.titleLabel?.value }}
          headingClassName={classNames('font-family', 'text-black')}
          headingLevel="h2"
          headingSize="sm"
          descriptionClassName="mb-8"
          headingIsRichText
        />
      </div>

      {/*Date Time*/}
      <div>
        <p className={classNames('text-grey text-sm')}>
          As of: {DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} {timeZoneName},{' '}
          {DateTime.now().toLocaleString(DateTime.DATE_SHORT)}
        </p>
      </div>

      {/* User Input Data Section */}
      <div>
        <p className={classNames('text-black')}>
          <span className="font-bold">Date of Service or Procedure:</span>{' '}
          {priorAuthInputs?.DateOfService}
        </p>
        <p className={classNames('text-black')}>
          <span className="font-bold">Member ID:</span> {priorAuthInputs?.MemberId}
        </p>

        {responseData?.GroupNumber && (
          <p className={classNames('text-black')}>
            <span className="font-bold">Group Number:</span> {responseData?.GroupNumber}
          </p>
        )}
        <p className={classNames('mb-6', 'text-black')}>
          <span className="font-bold">CPT Code:</span> {responseData?.ProcedureCode?.CPTCode}
        </p>
      </div>
      <div className={classNames('mb-6', 'text-black')}>
        <span className="font-bold">Description:</span> {responseData?.ProcedureCode?.Description}
      </div>
      {/* Special message for Medicare Advantage members */}
      {responseData?.GroupNumber === 'H0302' &&
        resultMessage?.fields?.medicareAdvantageDescription && (
          <div className={classNames('mb-4', 'mt-4', 'text-black')}>
            <RichText
              field={{ value: resultMessage?.fields?.medicareAdvantageDescription?.value }}
              editable={false}
            />
          </div>
        )}

      {/* RichText Section */}
      <div className={classNames('rte', 'mb-4', 'mt-4', 'text-sm', 'italic', 'text-black')}>
        <RichText field={{ value: resultMessage?.fields?.messageText?.value }} editable={false} />
      </div>
    </div>
  );
};

export default PriorAuthLookupAuthFalseResults;
