import { AxiosDataFetcher } from '@sitecore-jss/sitecore-jss';
import axios, { AxiosResponse } from 'axios';
import { DevLog } from 'lib/utils/logging-utils';

type NexusTokenResponse = {
  token_type: 'Bearer' | string;
  expires_in: number;
  access_token: string;
  scope: string;
};

export type ContractSpecialistSearchResponse = {
  taxID: string;
  zip: string;
  fullName: string;
  email: string;
  city: string;
  phone: string;
  specdesc: string;
};

export type CPTCodeRecord = {
  CPTCode: string;
  Category: string;
  SubCategory: string;
  Description: string;
  LongDescription: string;
  EffectiveDate: string;
  ExpirationDate: string | null;
  Comments: string | null;
  Descriptor: string | null;
  RuleCount: number;
};

export type GetCPTCodeSearchRequest = {
  cptCodeOrLongDescription: string;
};

export const withRetry = <T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: (...x: any[]) => Promise<T>,
  retry = 5,
  functionName: string | undefined = undefined
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (...args: any[]) => {
    while (retry > 0) {
      try {
        return await func(...args);
      } catch (err) {
        retry--;
        console.error(functionName ?? func.name, ': Error loading data, remaining retries:', retry);
        if (retry <= 0) {
          console.error(err);
        }
      }
    }
    return null;
  };
};

export const getContractSpecialistSearchResults = async (
  specialty: string,
  zipCode: string,
  taxId: string
): Promise<ContractSpecialistSearchResponse[] | null> => {
  const data = new URLSearchParams({ specialty: specialty, zipCode: zipCode, taxId: taxId });

  return await getData(
    `${process.env.NEXUS_API_DOMAIN}/api/ContractSpecialist/GetSearchResults?${data}`
  );
};

export const getProcedureCodes = async (
  cptCodeOrLongDescription: string
): Promise<CPTCodeRecord[] | null> => {
  const data = new URLSearchParams({ cptCodeOrLongDescription: cptCodeOrLongDescription });

  console.log('NEXUS_API_DOMAIN: ', process.env.NEXUS_API_DOMAIN);
  return await getData(`${process.env.NEXUS_API_DOMAIN}/api/Priorauth/GetProcedureCodes?${data}`);
};

export const getZipCodes = async (
  fetcher: AxiosDataFetcher | null = null,
  tokenResponse: NexusTokenResponse | null = null
): Promise<string[] | null> => {
  return await getData(
    `${process.env.NEXUS_API_DOMAIN}/api/ContractSpecialist/GetAllZipCodes`,
    fetcher,
    tokenResponse
  );
};

export const getProviderSpecialties = async (
  fetcher: AxiosDataFetcher | null = null,
  tokenResponse: NexusTokenResponse | null = null
): Promise<string[] | null> => {
  return await getData(
    `${process.env.NEXUS_API_DOMAIN}/api/ContractSpecialist/GetAllSpecialties`,
    fetcher,
    tokenResponse
  );
};

export const getStargateApiToken = async (
  fetcher: AxiosDataFetcher | null = null,
  tokenResponse: NexusTokenResponse | null = null
): Promise<string[] | null> => {
  DevLog('process.env.NEXUS_API_DOMAIN===>', process.env.NEXUS_API_DOMAIN);
  return await getDataForStargateApi(
    `${process.env.NEXUS_API_DOMAIN}/api/Okta/StarGate/GetAuthToken`,
    fetcher,
    tokenResponse
  );
};

const getDataForStargateApi = async <T>(
  url: string,
  fetcher: AxiosDataFetcher | null = null,
  tokenResponse: NexusTokenResponse | null = null
): Promise<T | null> => {
  fetcher = fetcher ?? new AxiosDataFetcher();
  const authHeader = await getAuthHeader(fetcher, tokenResponse);

  DevLog('authHeader===>', authHeader);
  const response = await axios.get<T>(url, { headers: authHeader });
  DevLog('response===>', response);

  return response?.data;
};

const getData = async <T>(
  url: string,
  fetcher: AxiosDataFetcher | null = null,
  tokenResponse: NexusTokenResponse | null = null
): Promise<T | null> => {
  fetcher = fetcher ?? new AxiosDataFetcher();
  const authHeader = await getAuthHeader(fetcher, tokenResponse);

  const response = await fetcher.get<T>(url, { headers: authHeader });

  return response.data;
};

async function getAuthHeader(
  fetcher: AxiosDataFetcher | null,
  tokenResponse: NexusTokenResponse | null
) {
  tokenResponse = tokenResponse ?? (await getNexusToken(fetcher));

  const authHeader = { Authorization: `Bearer ${tokenResponse?.access_token}` };
  return authHeader;
}

export async function getNexusToken(fetcher: AxiosDataFetcher | null = null) {
  try {
    fetcher = fetcher ?? new AxiosDataFetcher();

    const data = new URLSearchParams();

    data.append('client_id', process.env.NEXUS_CLIENT_ID as string);
    data.append('client_secret', process.env.NEXUS_CLIENT_SECRET as string);
    data.append('grant_type', 'client_credentials');

    const tokenUrl = process.env.NEXUS_TOKEN_URL as string;

    const tokenResponse = (await fetcher.post(tokenUrl, data, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    })) as AxiosResponse<NexusTokenResponse>;
    return tokenResponse.data;
  } catch (err) {
    console.error(err);
    return null;
  }
}
