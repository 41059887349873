import { DevLog } from 'lib/utils/logging-utils';
import { useState, useEffect } from 'react';

const useLocalTimeZone = () => {
  const [timeZoneName, setTimeZoneName] = useState('');

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: userTimeZone,
      timeZoneName: 'short',
    });

    const parts = formatter.formatToParts(new Date());
    const tzNamePart = parts.find((part) => part.type === 'timeZoneName');

    if (tzNamePart) {
      setTimeZoneName(tzNamePart.value);
    }
  }, []);
  DevLog('timeZoneName', timeZoneName);
  return timeZoneName;
};

export default useLocalTimeZone;
