/* eslint-disable @next/next/no-img-element */
// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import * as Yup from 'yup';
import PriorAuthProcedureCodeLookup from 'components/authorable/priorAuthLookup/PriorAuthProcedureCodeLookup/PriorAuthProcedureCodeLookup';
import Container from 'components/helpers/Container/Container';
import FormSelectList from 'components/helpers/FormElements/FormSelectList/FormSelectList';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { useEffect, useState, ChangeEvent } from 'react';
//https://react-tooltip.com/docs/getting-started
import { Tooltip } from 'react-tooltip';
import PriorAuthLookupAuthNullResults from './PriorAuthLookupAuthNullResults';
import PriorAuthLookupDefaultResults from './PriorAuthLookupDefaultResults';
import PriorAuthLookupAuthFalseResults from './PriorAuthLookupFalseResults';
import PriorAuthLookupAuthTrueResults from './PriorAuthLookupAuthTrueResults';
import { DevError, DevLog } from 'lib/utils/logging-utils';
import { isDateInBetween, parseDate } from 'lib/utils/datetime-utils';
import { AuthErrorCodes, AuthMessages, AuthResults } from 'Utility/Constants-PriorAuth';
import axios from 'axios';
export type PriorAuthLookupProps = Sitecore.Override.ComponentBase &
  Feature.Components.PriorAuthLookup.Fields.PriorAuthLookup;

export type ResultMessage = Feature.Data.Priorauth.ResultMessages.Fields.ResultMessages & {
  comments?: string;
};
export type ResultMessages = ResultMessage & Sitecore.Override.ItemEx[];

export type PriorAuthPlanType = Sitecore.Override.ItemEx &
  Feature.Data.Priorauth.PriorauthLookupOption.Fields.PriorauthLookupOption;

export type PriorAuthInputs = {
  MemberId: string;
  DateOfService: string;
  ProcedureCode: string;
};

const dateSchema = Yup.string()
  .required()
  .matches(/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/)
  .test('isValidDate', 'Date must be on or after 01/01/2025', (value) => {
    if (!value) return false; // Required check

    const [month, day, year] = value.split('/').map(Number);
    const enteredDate = new Date(year, month - 1, day); // JS months are 0-based
    const minDate = new Date(2025, 0, 1); // 01/01/2025

    return enteredDate >= minDate;
  });

const memberIdSchema = Yup.string()
  .matches(/^(?:[A-Za-z0-9]{12}|[Rr]\d{8})$/, 'Enter valid member id')
  .required('Member Id is required');

const PriorAuthLookup = ({ fields, rendering }: PriorAuthLookupProps): JSX.Element => {
  // Fail out if we don't have any field
  if (!fields) {
    return <></>;
  }

  // State to manage the currently displayed component
  const [currentResultComponent, setCurrentResultComponent] = useState<string>(AuthResults.DEFAULT);
  const [dateOfServiceProcedure, setDateOfServiceProcedure] = useState<string>('');
  const [memberId, setMemberId] = useState<string>('');
  const [disableSearchButton, setDisableSearchButton] = useState<boolean>(true);
  const [selectedCptCode, setSelectedCptCode] = useState<string>('');
  const [isDosValid, setIsDosValid] = useState<boolean | null>(null);
  const [planName, setPlanName] = useState<string>(fields?.planType[0]?.name);
  const [responseData, setResponseData] = useState<any>(null);
  const [isValidMemberId, setIsValidMemberId] = useState<boolean | null>(null);
  const [authFormData, setAuthFormData] = useState();
  const [selectedExpirationDate, setSelectedExpirationDate] = useState<string>('');
  const [enteredCptCodeOrDesc, setEnteredCptCodeOrDesc] = useState<string>('');
  const [selectedEffectiveDate, setSelectedEffectiveDate] = useState<string>('');
  const [selectedCPTCodeComments, setSelectedCPTCodeComments] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [dosErrorMessage, setDosErrorMessage] = useState<string>('');
  const [disableAllFields, setDisableAllFields] = useState(true);
  const [isChsMember, setIsChsMember] = useState(false);
  const [isIazMember, setIsIazMember] = useState(false);
  const [is99dMember, setIs99dMember] = useState(false);
  const [isK8yMember, setIsK8yMember] = useState(false);
  const [isK8zMember, setIsK8zMember] = useState(false);
  const [isMkqMember, setIsMkqMember] = useState(false);
  const [isNbtMember, setIsNbtMember] = useState(false);
  const [isPtpMember, setIsPtpMember] = useState(false);
  const [isXbsMember, setIsXbsMember] = useState(false);
  const [hasSearchResult, setHasSearchResult] = useState(false);
  const [clearAutocomplete, setClearAutocomplete] = useState(false);
  const [isOptionVisible] = useState(false);
  const [priorAuthInputs, setPriorAuthInputs] = useState({});
  const [procedureCodeApiError, setProcedureCodeApiError] = useState(false);

  //state management: plantype form component
  //setting the default plan type option is the first in the datasoure item
  //the field name is "Prior Auth Lookup Options"
  const [planTypeSelectionData, setPlanTypeSelectionData] = useState<PriorAuthPlanType | undefined>(
    fields?.planType[0] as PriorAuthPlanType
  );

  //state management: plan type options
  const [selectedPlanType, setSelectedPlanType] = useState<any | null>(fields?.planType[0]?.name);

  const [resultMessage, setResultMessage] = useState<ResultMessages>();

  useEffect(() => {
    DevLog('memberid.length= ', memberId.length);
    const isSearchButtonEnabled =
      planName !== 'Select' &&
      (isValidMemberId || (memberId.length >= 9 && memberId.length <= 12)) &&
      isDosValid &&
      selectedCptCode?.length > 0 &&
      enteredCptCodeOrDesc.length > 0;

    setDisableSearchButton(!isSearchButtonEnabled);
  }, [isValidMemberId, memberId, isDosValid, selectedCptCode, planName, enteredCptCodeOrDesc]);

  useEffect(() => {
    planName === 'Select' ? setDisableAllFields(true) : setDisableAllFields(false);
  }, [planName]);

  useEffect(() => {
    if (procedureCodeApiError === true) {
      DevLog('procedureCodeApiError useEffect =', procedureCodeApiError);

      const authResultMessageList = planTypeSelectionData?.fields
        ?.authResultMessages as unknown as ResultMessages[];

      const resultMessage = authResultMessageList?.find(
        (item) => item?.fields?.code?.value === AuthErrorCodes.PROCEDURE_CODE_API_ERROR
      );

      setResultMessage(resultMessage);
      setCurrentResultComponent(AuthResults.PROCEDURE_CODE_API_ERROR);
      // Set to true to show clear button on error result message.
      setHasSearchResult(true);
    }
  }, [procedureCodeApiError]);

  //Section: State handlers

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
    let formattedValue = '';

    if (value.length > 0) {
      formattedValue = value.substring(0, 2);
    }
    if (value.length > 2) {
      formattedValue += '/' + value.substring(2, 4);
    }
    if (value.length > 4) {
      formattedValue += '/' + value.substring(4, 8);
    }

    setDateOfServiceProcedure(formattedValue);
  };

  const validateDateOfService = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    dateSchema
      .validate(value)
      .then(() => {
        DevLog('DOS is valid');
        setIsDosValid(true);
        setDosErrorMessage('');
      })
      .catch((err) => {
        DevLog('DOS not valid');
        DevError(err);
        setIsDosValid(false);
        if (err.type === 'required') {
          setDosErrorMessage('DOS is required');
        } else if (err.type === 'matches') {
          setDosErrorMessage(
            planTypeSelectionData?.fields?.dateOfServiceErrorMessage?.value ||
              'Enter a valid date - MM/DD/YYYY'
          );
          DevLog(planTypeSelectionData?.fields?.dateOfServiceErrorMessage?.value);
        } else {
          setDosErrorMessage(
            planTypeSelectionData?.fields?.dateOfServiceErrorMessage?.value ||
              'Enter a valid date - MM/DD/YYYY'
          );
          DevLog(planTypeSelectionData?.fields?.dateOfServiceErrorMessage?.value);
        }
      });
  };

  const handleMemberIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMemberId(value);
  };

  const validateMemberId = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    DevLog('validateMemberId');
    memberIdSchema
      .validate(value)
      .then(() => {
        setIsValidMemberId(true);
        //Reset all special prefix cases
        setIsChsMember(false);
        setIsIazMember(false);
        setIs99dMember(false);
        setIsK8yMember(false);
        setIsK8zMember(false);
        setIsMkqMember(false);
        setIsNbtMember(false);
        setIsPtpMember(false);
        setIsXbsMember(false);
        // Additional CHS Out of State logic if validation succeeds
        if (/^\d{12}$/.test(value)) {
          setIsChsMember(true);
        } else if (/^iaz/i.test(value)) {
          setIsIazMember(true);
        } else if (/^99d/i.test(value)) {
          setIs99dMember(true);
        } else if (/^k8y/i.test(value)) {
          setIsK8yMember(true);
        } else if (/^k8z/i.test(value)) {
          setIsK8zMember(true);
        } else if (/^mkq/i.test(value)) {
          setIsMkqMember(true);
        } else if (/^nbt/i.test(value)) {
          setIsNbtMember(true);
        } else if (/^ptp/i.test(value)) {
          setIsPtpMember(true);
        } else if (/^xbs/i.test(value)) {
          setIsXbsMember(true);
        }
      })
      .catch((err) => {
        DevError(err);
        setIsValidMemberId(false);
        setIsChsMember(false);
        setIsIazMember(false);
        setIs99dMember(false);
        setIsK8yMember(false);
        setIsK8zMember(false);
        setIsMkqMember(false);
        setIsNbtMember(false);
        setIsPtpMember(false);
        setIsXbsMember(false);
      });
  };

  const handleCptCodeAndDescription = (
    cptCode: string,
    expirationDate: string,
    effectiveDate: string,
    comments: string
  ) => {
    setSelectedCptCode(cptCode);
    setSelectedExpirationDate(expirationDate);
    setSelectedEffectiveDate(effectiveDate);
    setSelectedCPTCodeComments(comments);
  };
  const handleCptCodeChange = (value: string) => {
    DevLog('handleCptCodeChange');
    setEnteredCptCodeOrDesc(value);
    if (enteredCptCodeOrDesc?.length === 0) {
      setSelectedCptCode('');
    }
  };

  // Section: Variables
  const minHeight = 'min-h-[450px]';
  const searchIcon = fields?.searchIcon?.value?.src;

  const componentAnchorId = rendering?.uid;

  function handelPlanTypeDataSelection(value: any) {
    const selectedPlanType: any = fields?.planType.filter((el) => el.name === value?.value);
    if (selectedPlanType != null || selectedPlanType != undefined) {
      setPlanTypeSelectionData(selectedPlanType[0]);
      setSelectedPlanType(value);
      setPlanName(value?.value);
    }
  }

  function clearResults() {
    DevLog('clearResults called');
    // Reset all form-related states
    setDateOfServiceProcedure('');
    setMemberId('');
    setSelectedCptCode('');
    setEnteredCptCodeOrDesc('');
    setSelectedExpirationDate('');
    setSelectedEffectiveDate('');
    setSelectedCPTCodeComments('');
    setIsDosValid(null);
    setIsValidMemberId(null);
    setDisableSearchButton(true);
    setResponseData(null);
    setCurrentResultComponent(AuthResults.DEFAULT);
    setHasSearchResult(false);
    setClearAutocomplete(true);
    setTimeout(() => setClearAutocomplete(false), 0);
  }

  const handleSearch = async () => {
    if (!isValidMemberId) {
      DevLog('Invalid member ID, API call blocked');
      return;
    }

    setLoading(true);
    const paInputs = {
      MemberId: memberId,
      DateOfService: dateOfServiceProcedure,
      ProcedureCode: selectedCptCode,
    };
    setPriorAuthInputs(paInputs);

    const requestBody = {
      requestSource: 'Public Portal',
      userType: 'Member', //selectedPlanType?.value, // Change this to 'Provider' or 'Employer' to test different cases
      memberId: memberId,
      dateOfService: dateOfServiceProcedure,
      procedures: [
        {
          code: selectedCptCode,
        },
      ],
    };

    try {
      const authResultMessageList = planTypeSelectionData?.fields
        ?.authResultMessages as unknown as ResultMessages[];
      const expirationDate = parseDate(selectedExpirationDate, '');
      const dateOfService = parseDate(dateOfServiceProcedure, '');
      // The stargate api is called only if dateofservice is valid, otherwise it goes to dos invalid screen.
      if (isChsMember) {
        DevLog('no api call here, isChsMember', isChsMember);
        setCurrentResultComponent(AuthResults.CHS_CODE_VALIDATION);
        // Error Code : 502 for procedurecode api expirationdate if null.
        const resultMessage = authResultMessageList?.find(
          (item) => item?.fields?.code?.value === AuthErrorCodes.AUTH_CHS_CODE_VALIDATOR //502
        );
        setResultMessage(resultMessage);
        setHasSearchResult(true);
      } else if (isIazMember) {
        DevLog('no api call here, isIazMember', isIazMember);
        setCurrentResultComponent(AuthResults.IAZ_CODE_VALIDATION);
        const resultMessage = authResultMessageList?.find(
          (item) => item?.fields?.code?.value === AuthErrorCodes.AUTH_IAZ_CODE_VALIDATOR //'503'
        );
        setResultMessage(resultMessage);
        setHasSearchResult(true);
      } else if (is99dMember) {
        DevLog('no api call here, is99dMember', is99dMember);
        setCurrentResultComponent(AuthResults.CODE_VALIDATION_99D);
        const resultMessage = authResultMessageList?.find(
          (item) => item?.fields?.code?.value === AuthErrorCodes.AUTH_99D_CODE_VALIDATOR //'503'
        );
        setResultMessage(resultMessage);
        setHasSearchResult(true);
      } else if (isK8yMember) {
        DevLog('no api call here, isK8yMember', isK8yMember);
        setCurrentResultComponent(AuthResults.K8Y_CODE_VALIDATION);
        const resultMessage = authResultMessageList?.find(
          (item) => item?.fields?.code?.value === AuthErrorCodes.AUTH_K8Y_CODE_VALIDATOR //'503'
        );
        setResultMessage(resultMessage);
        setHasSearchResult(true);
      } else if (isK8zMember) {
        DevLog('no api call here, isK8zMember', isK8zMember);
        setCurrentResultComponent(AuthResults.K8Z_CODE_VALIDATION);
        const resultMessage = authResultMessageList?.find(
          (item) => item?.fields?.code?.value === AuthErrorCodes.AUTH_K8Z_CODE_VALIDATOR //'503'
        );
        setResultMessage(resultMessage);
        setHasSearchResult(true);
      } else if (isMkqMember) {
        DevLog('no api call here, isMkqMember', isMkqMember);
        setCurrentResultComponent(AuthResults.MKQ_CODE_VALIDATION);
        const resultMessage = authResultMessageList?.find(
          (item) => item?.fields?.code?.value === AuthErrorCodes.AUTH_MKQ_CODE_VALIDATOR //'503'
        );
        setResultMessage(resultMessage);
        setHasSearchResult(true);
      } else if (isNbtMember) {
        DevLog('no api call here, isNbtMember', isNbtMember);
        setCurrentResultComponent(AuthResults.NBT_CODE_VALIDATION);
        const resultMessage = authResultMessageList?.find(
          (item) => item?.fields?.code?.value === AuthErrorCodes.AUTH_NBT_CODE_VALIDATOR //'503'
        );
        setResultMessage(resultMessage);
        setHasSearchResult(true);
      } else if (isPtpMember) {
        DevLog('no api call here, isPtpMember', isPtpMember);
        setCurrentResultComponent(AuthResults.PTP_CODE_VALIDATION);
        const resultMessage = authResultMessageList?.find(
          (item) => item?.fields?.code?.value === AuthErrorCodes.AUTH_PTP_CODE_VALIDATOR //'503'
        );
        setResultMessage(resultMessage);
        setHasSearchResult(true);
      } else if (isXbsMember) {
        DevLog('no api call here, isXbsMember', isXbsMember);
        setCurrentResultComponent(AuthResults.XBS_CODE_VALIDATION);
        const resultMessage = authResultMessageList?.find(
          (item) => item?.fields?.code?.value === AuthErrorCodes.AUTH_XBS_CODE_VALIDATOR //'503'
        );
        setResultMessage(resultMessage);
        setHasSearchResult(true);
      } else if (isDateInBetween(selectedEffectiveDate, expirationDate, dateOfService)) {
        const response = await axios.get('/api/nexus/get-prior-auth-message', {
          params: {
            requestBody: JSON.stringify(requestBody),
          },
        });
        const data = response?.data;

        setAuthFormData(data);

        if (response.status === 200) {
          DevLog('api call here on ui successful response.status = ', response.status);
          // Process and display the response data
          setResponseData(data);
          setHasSearchResult(true);

          if (data.AuthRequired === null) {
            setCurrentResultComponent(AuthResults.AUTH_NULL);
            let errorCode = AuthErrorCodes.AUTH_FAILURE; //404
            switch (data?.MessageText?.toUpperCase()) {
              case AuthMessages.INVALID_PREFIX.toUpperCase():
                errorCode = AuthErrorCodes.AUTH_NULL_BAD_PREFIX; // '4041';
                break;
              case AuthMessages.INVALID_MEMBER_ID.toUpperCase():
                errorCode = AuthErrorCodes.AUTH_NULL_BAD_MEMBER_ID; // '4042';
                break;
              case AuthMessages.MEMBER_NOT_ACTIVE.toUpperCase():
                errorCode = AuthErrorCodes.AUTH_NULL_INACTIVE; // '4043';
                break;
            }

            const resultMessage = authResultMessageList?.find(
              (item) => item?.fields?.code?.value?.toUpperCase() === errorCode?.toUpperCase()
            );

            setResultMessage(resultMessage);
          } else if (data.AuthRequired === true) {
            setCurrentResultComponent(AuthResults.AUTH_TRUE);
            const resultMessage = authResultMessageList?.find(
              (item) =>
                item?.fields?.code?.value?.toUpperCase() === AuthErrorCodes.AUTH_TRUE.toUpperCase() // 200
            );
            setResultMessage(resultMessage);
          } else if (data.AuthRequired === false) {
            setCurrentResultComponent(AuthResults.AUTH_FALSE);
            const resultMessage = authResultMessageList?.find(
              (item) =>
                item?.fields?.code?.value?.toUpperCase() === AuthErrorCodes.AUTH_FALSE.toUpperCase() //'401'
            );
            setResultMessage(resultMessage);
          }
        }
      } else {
        setCurrentResultComponent(AuthResults.CPT_CODE_VALIDATION);
        // Error Code : 501 for procedurecode api expirationdate if null.
        const resultMessage = authResultMessageList?.find(
          (item) =>
            item?.fields?.code?.value?.toUpperCase() === AuthErrorCodes.CPT_ERROR.toUpperCase() //'501'
        );
        (resultMessage as ResultMessage).comments = selectedCPTCodeComments;
        setResultMessage(resultMessage);
        setHasSearchResult(true);
      }
    } catch (error) {
      DevError('Error fetching authorization data:', error);
      const authResultMessageList = planTypeSelectionData?.fields
        ?.authResultMessages as unknown as ResultMessages[];

      const resultMessage = authResultMessageList?.find(
        (item) => item?.fields?.code?.value === AuthErrorCodes.STARGATE_API_ERROR
      );

      setResultMessage(resultMessage);
      setCurrentResultComponent(AuthResults.STARGATE_API_ERROR);
      // Set to true to show clear button on error result message.
      setHasSearchResult(true);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setLoading(false);
  };

  //Section: UI
  return (
    // the main container that has the theme
    <Container
      theme="theme-blue--light"
      dataComponent="authorable/priorauthlookup"
      className={classNames('')}
      id={componentAnchorId}
      fullWidth={true}
    >
      {/* the main element that controls the padding for all content */}
      <div
        className={classNames(
          'priorauth-content-body',
          'container-content-padding-x',
          'md:space-y-12',
          'space-y-6',
          'p-[35px]',
          'mx-auto',
          'max-w-[1344px]'
        )}
      >
        {/* the heading section of the form */}
        <section className={classNames('priorauth-heading')}>
          <h2 className={classNames('text-5xl leading-[70px] font-light')}>
            {fields?.priorAuthTitle?.value}
          </h2>
        </section>
        {isOptionVisible && (
          <section className={classNames('priorauth-options-section')}>
            {/* <label htmlFor={`${componentAnchorId}-selectLabel`}>{fields?.optionsLabel?.value}</label> */}

            <FormSelectList
              id={`${componentAnchorId}-selectLabel`}
              name={`${componentAnchorId}-selectLabel`}
              label={{
                text: fields?.optionsLabel?.value,
                className: classNames('text-base', 'text-gray-darkest'),
              }}
              placeholder={undefined}
              singleValueCss="text-gray-darkest"
              classNamePrefix="priorauth"
              className={classNames(
                'max-w-[320px]',
                'border-x border-y border-gray-medium rounded-t-md rounded-b-md'
              )}
              options={fields?.planType
                .filter((opt) => opt.name !== 'Select')
                .map((opt) => {
                  return {
                    value: opt.name,
                    label: (opt as PriorAuthPlanType)?.fields?.optionTitle?.value,
                  };
                })}
              defaultValue={{ value: fields?.planType[0]?.name, label: fields?.planType[0]?.name }}
              isSearchable={false}
              callBackChange={handelPlanTypeDataSelection}
              value={selectedPlanType}
            />
          </section>
        )}
        {planTypeSelectionData?.fields?.optionDescription?.value && (
          <section className={classNames('priorauth-options-description')}>
            <RichTextA11yWrapper field={planTypeSelectionData?.fields?.optionDescription} />
          </section>
        )}

        {/* the form section */}
        <div
          className={classNames(
            'priorauth-form-shell',
            'rounded-lg bg-white shadow-card',
            minHeight
          )}
        >
          <div
            className={classNames(
              'border-b border-gray border-l border-r  md:p-8 rounded-b-lg pt-5 text-gray-dark border-t rounded-t-lg',
              minHeight
            )}
          >
            <div
              className={classNames(
                'priorauth-form-body',
                'grid',
                'grid-cols-1',
                'md:grid-cols-2',
                'md:gap-5',
                'lg:gap-12',
                'space-y-6',
                'md:space-y-0',
                'relative',
                'lg:p-[35px]',
                'flex',
                'divide-x-0',
                `divide-[#E6E6E6]`,
                'sm:divide-x-0',
                'md:divide-x-2',
                'lg:divide-x-2',
                'divide-y-2',
                'divide-gray-300',
                'md:divide-y-0',
                'lg:divide-y-0',
                'px-1'
              )}
            >
              <div
                className={classNames(
                  'sm:mb-0',
                  'md:mb-0',
                  'lg:mb-8',
                  'space-y-7',
                  'md:space-y-7',
                  'lg:space-y-10',
                  'px-6',
                  disableAllFields ? 'disabled-field opacity-50' : ''
                )}
              >
                <div>
                  <label className="flex mb-2 text-[14px] font-[400] leading-[1.25]">
                    {planTypeSelectionData?.fields?.dateOfServiceTitle?.value}
                    {planTypeSelectionData?.fields?.dateOfServiceTooltip?.value !== undefined &&
                      planTypeSelectionData?.fields?.dateOfServiceTooltip?.value.length > 0 && (
                        <>
                          <i data-tooltip-id="dos-tooltip" className="ml-1">
                            <img
                              src={planTypeSelectionData?.fields?.tooltipIcon?.value?.src}
                              className="h-[20px] w-[20px]"
                            />
                          </i>
                          <Tooltip
                            id="dos-tooltip"
                            className="text-theme-text bg-theme-bg priorauth-tooltip "
                            html={planTypeSelectionData?.fields?.dateOfServiceTooltip?.value}
                            place="top"
                            offset={5}
                            openOnClick={true}
                          />
                        </>
                      )}
                  </label>
                  <input
                    type="text"
                    className={classNames(
                      'w-full lg:w-[483px] h-[56px] p-[16px] rounded-[4px] border-2 bg-white focus:border-blue focus:bg-white',
                      isDosValid === false ? '!border-error' : 'focus:!border-blue',
                      disableAllFields ? 'placeholder-gray-medium' : ''
                    )}
                    value={dateOfServiceProcedure}
                    onChange={handleDateChange}
                    onBlur={validateDateOfService}
                    placeholder="MM/DD/YYYY"
                    disabled={disableAllFields}
                    maxLength={10}
                  />
                  <div
                    className={classNames(
                      'min-h-[24px]',
                      isDosValid === false ? 'text-error visible' : 'invisible'
                    )}
                  >
                    {dosErrorMessage}
                  </div>
                </div>

                {/* Procedure Code Selection*/}
                <div className="!mt-4">
                  <label className="flex text-[14px] font-[400] leading-[1.25]">
                    {planTypeSelectionData?.fields?.procedureKeywordTitle?.value}
                    {planTypeSelectionData?.fields?.procedureKeywordTooltip?.value !== undefined &&
                      planTypeSelectionData?.fields?.procedureKeywordTooltip?.value.length > 0 && (
                        <>
                          <i data-tooltip-id="cpt-tooltip" className="ml-1">
                            <img
                              src={planTypeSelectionData?.fields?.tooltipIcon?.value?.src}
                              className="h-[20px] w-[20px]"
                            />
                          </i>
                          <Tooltip
                            id="cpt-tooltip"
                            className="text-theme-text bg-theme-bg priorauth-tooltip"
                            html={planTypeSelectionData?.fields?.procedureKeywordTooltip?.value}
                            place="top"
                            offset={5}
                            openOnClick={true}
                          />
                        </>
                      )}
                  </label>{' '}
                  <div className="mt-2">
                    <PriorAuthProcedureCodeLookup
                      searchIcon={searchIcon}
                      handleCptCodeAndDescription={handleCptCodeAndDescription}
                      handleCptCodeChange={handleCptCodeChange}
                      errorMessage={
                        planTypeSelectionData?.fields?.procedureKeywordErrorMessage?.value
                      }
                      disableAllFields={disableAllFields}
                      clear={clearAutocomplete}
                      onApiError={(error) => setProcedureCodeApiError(error)}
                    />
                  </div>
                </div>

                {/* Member Id Input*/}
                <div className="!mt-4">
                  <label className="mb-2 flex text-[14px] font-[400] leading-[1.25]">
                    {planTypeSelectionData?.fields?.memberIdTitle?.value}
                    {planTypeSelectionData?.fields?.memberIdTooltip?.value !== undefined &&
                      planTypeSelectionData?.fields?.memberIdTooltip?.value?.length > 0 && (
                        <>
                          <i data-tooltip-id="member-id-tooltip" className="ml-1">
                            <img
                              className="h-[20px] w-[20px]"
                              src={planTypeSelectionData?.fields?.tooltipIcon?.value?.src}
                            />
                          </i>
                          <Tooltip
                            id="member-id-tooltip"
                            className="text-theme-text bg-theme-bg priorauth-tooltip"
                            html={planTypeSelectionData?.fields?.memberIdTooltip?.value}
                            place="top"
                            offset={5}
                            openOnClick={true}
                          />
                        </>
                      )}
                  </label>
                  <input
                    type="text"
                    className={classNames(
                      'w-full lg:w-[483px] h-[56px] p-[16px] rounded-[4px] border-2 bg-white focus:border-blue focus:bg-white',
                      isValidMemberId === false ? '!border-error' : 'focus:!border-blue',
                      disableAllFields ? 'placeholder-gray-medium' : ''
                    )}
                    placeholder="Enter ID"
                    value={memberId}
                    onChange={handleMemberIdChange}
                    onBlur={validateMemberId}
                    disabled={disableAllFields}
                    maxLength={12}
                  />

                  <div
                    className={classNames(
                      'min-h-[24px]',
                      isValidMemberId === false ? 'text-error visible' : 'invisible'
                    )}
                  >
                    {planTypeSelectionData?.fields?.memberIdErrorMessage?.value}
                  </div>
                </div>

                <div>
                  <button
                    onClick={handleSearch}
                    disabled={disableSearchButton}
                    className={`${
                      disableSearchButton
                        ? 'btn-disabled-priorauth-submit'
                        : 'btn-enabled-priorauth-submit'
                    } flex justify-center items-center h-[56px] w-[167px] px-4 py-2 bg-gray-500 text-black rounded-[20pc] hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400`}
                  >
                    {loading ? fields?.searchLoadingText?.value : fields?.searchText?.value}
                    {!loading && (
                      <svg
                        className="w-5 h-5 mr-2 ml-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-4.35-4.35m1.35-5.65a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                    )}
                  </button>
                </div>
                <div>
                  <span>
                    <i>{fields?.searchDescription?.value}</i>
                  </span>
                </div>
              </div>
              <div
                className={classNames(
                  'prior-auth-results-section',
                  'mb-10',
                  'md:mb-0',
                  'space-y-6',
                  'px-6',
                  `md:${minHeight}`,
                  'min-h-[320px]',
                  'py-6',
                  'lg:py-0'
                )}
              >
                <div
                  onClick={clearResults}
                  className={classNames(
                    'clear-prior-auth-results',
                    'flex justify-end',
                    hasSearchResult === true ? 'text-blue visible' : 'invisible',
                    'text-base leading-[16px] font-regular',
                    'cursor-pointer'
                  )}
                >
                  {fields?.clearResultsTitle?.value}
                </div>
                {currentResultComponent === AuthResults.DEFAULT && (
                  <PriorAuthLookupDefaultResults fields={fields} rendering={rendering} />
                )}
                {currentResultComponent === AuthResults.CPT_CODE_VALIDATION && (
                  <PriorAuthLookupAuthNullResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                  />
                )}
                {currentResultComponent === AuthResults.CHS_CODE_VALIDATION && (
                  <PriorAuthLookupAuthNullResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                  />
                )}
                {currentResultComponent === AuthResults.IAZ_CODE_VALIDATION && (
                  <PriorAuthLookupAuthNullResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                  />
                )}
                {currentResultComponent === AuthResults.CODE_VALIDATION_99D && (
                  <PriorAuthLookupAuthNullResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                  />
                )}
                {currentResultComponent === AuthResults.K8Y_CODE_VALIDATION && (
                  <PriorAuthLookupAuthNullResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                  />
                )}
                {currentResultComponent === AuthResults.K8Z_CODE_VALIDATION && (
                  <PriorAuthLookupAuthNullResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                  />
                )}
                {currentResultComponent === AuthResults.MKQ_CODE_VALIDATION && (
                  <PriorAuthLookupAuthNullResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                  />
                )}
                {currentResultComponent === AuthResults.NBT_CODE_VALIDATION && (
                  <PriorAuthLookupAuthNullResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                  />
                )}
                {currentResultComponent === AuthResults.PTP_CODE_VALIDATION && (
                  <PriorAuthLookupAuthNullResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                  />
                )}
                {currentResultComponent === AuthResults.XBS_CODE_VALIDATION && (
                  <PriorAuthLookupAuthNullResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                  />
                )}
                {currentResultComponent === AuthResults.AUTH_NULL && (
                  <PriorAuthLookupAuthNullResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                  />
                )}
                {currentResultComponent === AuthResults.AUTH_TRUE &&
                  resultMessage !== undefined && (
                    <PriorAuthLookupAuthTrueResults
                      rendering={rendering}
                      resultMessage={resultMessage}
                      authFormData={authFormData}
                      priorAuthInputs={priorAuthInputs}
                    />
                  )}
                {currentResultComponent === AuthResults.AUTH_FALSE && (
                  <PriorAuthLookupAuthFalseResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                    responseData={responseData}
                    priorAuthInputs={priorAuthInputs}
                  />
                )}
                {currentResultComponent === AuthResults.STARGATE_API_ERROR && (
                  <PriorAuthLookupAuthNullResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                  />
                )}
                {currentResultComponent === AuthResults.PROCEDURE_CODE_API_ERROR && (
                  <PriorAuthLookupAuthNullResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={classNames('priorauth-footer', 'pb-6')}>
          <RichTextA11yWrapper field={fields?.footerText}></RichTextA11yWrapper>
        </div>
      </div>
    </Container>
  );
};

export default PriorAuthLookup;
