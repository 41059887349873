import React from 'react';
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import ImageWrapper from 'components/helpers/ImageWrapper/ImageWrapper';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Heading from 'components/helpers/Heading/Heading';

export type PriorAuthLookupDefaultResultsProps = Sitecore.Override.ComponentBase &
  Feature.Components.PriorAuthLookup.Fields.PriorAuthLookup;
export type ResultMessages = Feature.Data.Priorauth.ResultMessages.Fields.ResultMessages;

const PriorAuthLookupDefaultResults = ({ fields }: PriorAuthLookupDefaultResultsProps) => {
  const defaultResults = fields?.defaultResults as ResultMessages | undefined;
  const defaultResultsTitle =
    defaultResults?.fields?.titleLabel?.value || 'Results will appear here';

  const defaultResultsText =
    defaultResults?.fields?.messageText?.value ||
    'Prior authorization requirements for the group number and procedure code you enter will show up here.';

  const iconImageSrc = defaultResults?.fields?.iconImage?.value?.src;

  return (
    <div className={classNames('text-center', 'py-10', 'md:py-16')}>
      <div className={classNames('mb-6')}>
        <Heading
          headingText={{ value: defaultResultsTitle }}
          headingClassName={classNames('font-family')}
          headingLevel="h2"
          headingSize="md"
          descriptionClassName="mb-6"
          headingIsRichText
        />
      </div>
      <div className={classNames('flex', 'flex-row', 'justify-center')}>
        <ImageWrapper
          field={{
            value: {
              src: iconImageSrc,
              width: '72px',
              height: '72px',
            },
          }}
          imageClass={classNames('object-full')}
        />
      </div>
      <div className={classNames('py-6  flex flex-row justify-center')}>
        <div className={classNames('md:max-w-[418px]', 'max-w-[320px]')}>
          <RichText field={{ value: defaultResultsText }} editable={false} />
        </div>
      </div>
    </div>
  );
};

export default PriorAuthLookupDefaultResults;
