import React from 'react';
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import ImageWrapper from 'components/helpers/ImageWrapper/ImageWrapper';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Heading from 'components/helpers/Heading/Heading';
import { DevLog } from 'lib/utils/logging-utils';
import useLocalTimeZone from 'lib/hooks/useLocalTimeZone';
import { DateTime } from 'luxon';
import { PriorAuthInputs } from './PriorAuthLookup';

export type ResultMessages = Feature.Data.Priorauth.ResultMessages.Fields.ResultMessages &
  Sitecore.Override.ItemEx[];

export type PriorAuthLookupAuthTrueResultsProps = Sitecore.Override.ComponentBase &
  Feature.Components.PriorAuthLookup.Fields.PriorAuthLookup &
  ResultMessages &
  PriorAuthInputs &
  any;

const PriorAuthLookupAuthTrueResults = ({
  resultMessage,
  authFormData,
  priorAuthInputs,
}: PriorAuthLookupAuthTrueResultsProps) => {
  DevLog('PriorAuthLookupAuthFalseResults resultMessage = ', resultMessage);
  DevLog('PriorAuthLookupAuthFalseResults authFormData = ', authFormData);

  const defaultResultsTitle = resultMessage?.fields?.titleLabel?.value;
  const iconImageSrc = resultMessage?.fields?.iconImage?.value?.src;
  const timeZoneName = useLocalTimeZone();

  return (
    <div className={classNames('text-left', 'pb-12', 'pt-2', 'md:px-12')}>
      <div className={classNames('pb-4', 'flex', 'items-center', 'space-x-4')}>
        <ImageWrapper
          field={{
            value: {
              src: iconImageSrc,
              width: '32px',
              height: '28px',
            },
          }}
        />{' '}
        &nbsp;
        <Heading
          headingText={{ value: defaultResultsTitle }}
          headingClassName={classNames('font-family', 'text-black')}
          headingLevel="h2"
          headingSize="sm"
          descriptionClassName="mb-8"
          headingIsRichText
        />
      </div>

      {/*Date Time*/}
      <div>
        <p className={classNames('text-grey text-sm')}>
          As of: {DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} {timeZoneName},{' '}
          {DateTime.now().toLocaleString(DateTime.DATE_SHORT)}
        </p>
      </div>
      <div className={classNames('mb-6')}>
        <p className={classNames('text-black')}>
          <span className="font-bold">Date of Service or Procedure:</span>{' '}
          {priorAuthInputs?.DateOfService}
        </p>
        <p className={classNames('text-black')}>
          <span className="font-bold">Member ID:</span> {priorAuthInputs?.MemberId}
        </p>

        <p className={classNames('text-black')}>
          <span className="font-bold">Group Number:</span> {authFormData?.GroupNumber}
        </p>

        <p className={classNames('text-black')}>
          <span className="font-bold">Procedure Code:</span> {authFormData?.ProcedureCode?.CPTCode}
        </p>
      </div>

      <div className={classNames('mb-6', 'text-black')}>
        <span className="font-bold">Description:</span> {authFormData?.ProcedureCode?.Description}
      </div>
      {/* Special message for Medicare Advantage members */}
      {authFormData?.GroupNumber === 'H0302' &&
        resultMessage?.fields?.medicareAdvantageDescription && (
          <div className={classNames('mb-6', 'mt-4', 'text-black')}>
            <RichText
              field={{ value: resultMessage?.fields?.medicareAdvantageDescription?.value }}
              editable={false}
            />
          </div>
        )}
      {/* Start of Gold Card section of the result message */}
      {authFormData.GoldCard.toLowerCase() === 'no' ? (
        <div className={classNames('mb-6', 'mt-4', 'text-black')}>
          <RichText
            field={{ value: resultMessage?.fields?.goldCardNoMessage?.value }}
            editable={false}
          />
        </div>
      ) : authFormData.GoldCard.toLowerCase() === 'yes' ? (
        <div className={classNames('mb-6', 'mt-4', 'text-black')}>
          <RichText
            field={{ value: resultMessage?.fields?.goldCardYesMessage?.value }}
            editable={false}
          />
        </div>
      ) : null}
      {/* Start of Site of Service section of the result message */}
      {authFormData.SiteOfService.toLowerCase() === 'no' ? (
        <div className={classNames('mb-6', 'mt-4', 'text-black')}>
          <RichText
            field={{ value: resultMessage?.fields?.siteOfServiceNoMessage?.value }}
            editable={false}
          />
        </div>
      ) : authFormData.SiteOfService.toLowerCase() === 'yes' ? (
        <div className={classNames('mb-6', 'mt-4', 'text-black')}>
          <RichText
            field={{ value: resultMessage?.fields?.siteOfServiceYesMessage?.value }}
            editable={false}
          />
        </div>
      ) : authFormData.SiteOfService.toLowerCase() === 'see message' ? (
        <div className={classNames('mb-6', 'mt-4', 'text-black')}>
          <RichText
            field={{ value: resultMessage?.fields?.siteOfServiceSeeMessage?.value }}
            editable={false}
          />
        </div>
      ) : null}
      {/* Start of Authorization Manager section of the result message */}
      {authFormData.AuthManager.toLowerCase() === 'bcbsaz' ? (
        <div className={classNames('mb-6', 'mt-4', 'text-black')}>
          <RichText
            field={{ value: resultMessage?.fields?.authManagerBCBSAZMessage?.value }}
            editable={false}
          />
        </div>
      ) : authFormData.AuthManager.toLowerCase() === 'evicore' ? (
        <div className={classNames('mb-6', 'mt-4', 'text-black')}>
          <RichText
            field={{ value: resultMessage?.fields?.authManagerEvicoreMessage?.value }}
            editable={false}
          />
        </div>
      ) : authFormData.AuthManager.toLowerCase() === 'see message' ? (
        <div className={classNames('mb-6', 'mt-4', 'text-black')}>
          <RichText
            field={{ value: resultMessage?.fields?.authManagerSeeMessage?.value }}
            editable={false}
          />
        </div>
      ) : null}
      {/* Conditional/Optional Message Test from the Stargate API */}
      {authFormData?.MessageText && (
        <div className={classNames('mb-6', 'mt-4', 'text-black')}>
          <span className="font-bold">Message:</span>{' '}
          <RichText field={{ value: authFormData.MessageText }} editable={false} />
        </div>
      )}

      <div className={classNames('rte', 'mb-4', 'mt-4', 'text-sm', 'italic', 'text-black')}>
        <RichText field={{ value: resultMessage?.fields?.messageText?.value }} editable={false} />
      </div>
    </div>
  );
};

export default PriorAuthLookupAuthTrueResults;
