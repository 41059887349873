import React from 'react';
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Heading from 'components/helpers/Heading/Heading';
import { DevLog } from 'lib/utils/logging-utils';

export type PriorAuthLookupAuthNullResultsProps = Sitecore.Override.ComponentBase &
  Feature.Components.PriorAuthLookup.Fields.PriorAuthLookup &
  any;

export type ResultMessages = Feature.Data.Priorauth.ResultMessages.Fields.ResultMessages;

const PriorAuthLookupAuthNullResults = ({
  fields,
  resultMessage,
}: PriorAuthLookupAuthNullResultsProps) => {
  DevLog('fields', fields);
  DevLog('resultMessage', resultMessage);
  DevLog('resultMessage?.fields?.titleLabel?.value', resultMessage?.fields?.titleLabel?.value);
  return (
    <div className={classNames('text-center', 'pb-16', 'pt-16')}>
      <div className={classNames('pb-6')}>
        <Heading
          //headingText={resultMessage?.fields?.titleLabel?.value}
          headingText={{ value: resultMessage?.fields?.titleLabel?.value }}
          headingClassName={classNames('font-family')}
          headingLevel="h2"
          headingSize="md"
          descriptionClassName="mb-12"
          headingIsRichText
        />
      </div>
      <div className={classNames('rte', 'px-12', 'mb-6', 'mt-6')}>
        <RichText field={{ value: resultMessage?.fields?.messageText?.value }} editable={false} />
        <RichText field={{ value: resultMessage?.comments }} editable={false} />
      </div>
    </div>
  );
};

export default PriorAuthLookupAuthNullResults;
