export const IsDev = () => {
  return process.env.NEXT_PUBLIC_ENV_NAME !== 'prod';
};

export const DevLog = (msg?: any, ...optionalParams: any[]) => {
  if (process.env.NEXT_PUBLIC_ENV_NAME !== 'prod') {
    console.log(msg, optionalParams);
  }
};

export const DevWarn = (msg?: any, ...optionalParams: any[]) => {
  if (process.env.NEXT_PUBLIC_ENV_NAME !== 'prod') {
    console.warn(msg, optionalParams);
  }
};

export const DevError = (msg?: any, ...optionalParams: any[]) => {
  if (process.env.NEXT_PUBLIC_ENV_NAME !== 'prod') {
    console.error(msg, optionalParams);
  }
};
