import { DateTime } from 'luxon';

export const SERVER_TIME_ZONE = 'America/Phoenix';
export const DATE_LOCALE = 'en-US';

const renderDate = (date: string | DateTime | undefined, format: Intl.DateTimeFormatOptions) => {
  if (!date || date === '0001-01-01T00:00:00Z') {
    return '';
  }

  const dateObj = typeof date === 'string' ? DateTime.fromISO(date) : date;
  return dateObj.toLocaleString(format, { locale: DATE_LOCALE });
};

export const renderFormattedDate = (date: string | DateTime | undefined) => {
  const format: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: SERVER_TIME_ZONE,
  };
  return renderDate(date, format);
};

export const renderArticleFormattedDate = (
  date: string | DateTime | undefined,
  shortMonth = false
) => {
  const format: Intl.DateTimeFormatOptions = {
    weekday: undefined,
    year: 'numeric',
    month: shortMonth ? 'short' : 'long',
    day: 'numeric',
    timeZone: SERVER_TIME_ZONE,
  };
  return renderDate(date, format);
};

const isDateString = (dateString: string) => {
  const date = DateTime.fromISO(dateString);
  return date.isValid;
};

export const parseDate = (
  dateString: string | DateTime | undefined,
  format: string | undefined
) => {
  if (!dateString) {
    return new Date(9999, 11, 31).toISOString(); // Handle undefined or null dateString
  }
  if (!format) {
    format = 'MM/dd/yyyy';
  }

  if (dateString instanceof DateTime) {
    return dateString; // If dateString is already a DateTime object, return it
  }
  if (isDateString(dateString)) {
    return dateString; //If dateString is already a date string, return it
  }
  const parsedDate = DateTime.fromFormat(dateString, format);
  if (!parsedDate.isValid) {
    return new Date(9999, 11, 31).toISOString(); // Handle invalid date format
  }

  return parsedDate;
};

export const isDateGreaterThan = (
  date1: string | DateTime | undefined,
  date2: string | DateTime | undefined
) => {
  if (!date1 || !date2) {
    return false; // Handle null or undefined dates
  }

  const dt1 = typeof date1 === 'string' ? DateTime.fromISO(date1) : date1;
  const dt2 = typeof date2 === 'string' ? DateTime.fromISO(date2) : date2;

  return dt1.isValid && dt2.isValid && dt1 > dt2;
};

export const isDateLessThan = (
  date1: string | DateTime | undefined,
  date2: string | DateTime | undefined
) => {
  if (!date1 || !date2) {
    return false; // or handle the null case as needed
  }
  const dt1 = typeof date1 === 'string' ? DateTime.fromISO(date1) : date1;
  const dt2 = typeof date2 === 'string' ? DateTime.fromISO(date2) : date2;
  return dt1.isValid && dt2.isValid && dt1 < dt2;
};
export const isDateInBetween = (
  startDate: string | DateTime | undefined,
  endDate: string | DateTime | undefined,
  checkDate: string | DateTime | undefined
) => {
  if (!startDate || !endDate || !checkDate) {
    return false; // Handle null values
  }

  const start = typeof startDate === 'string' ? DateTime.fromISO(startDate) : startDate;
  const end = typeof endDate === 'string' ? DateTime.fromISO(endDate) : endDate;
  const check = typeof checkDate === 'string' ? DateTime.fromISO(checkDate) : checkDate;

  if (!start.isValid || !end.isValid || !check.isValid) {
    return false; // Handle invalid dates
  }

  return check >= start && check <= end;
};
